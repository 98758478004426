<template>
  <!-- begin:: Aside -->
  <div
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto h-100"
    id="kt_aside"
    ref="kt_aside"
  >
    <!-- begin:: Aside -->
    <KTBrand></KTBrand>
    <!-- end:: Aside -->

    <!-- begin:: Aside Menu -->
    <div
      class="aside-menu-wrapper flex-column-fluid menu-mobile"
      id="kt_aside_menu_wrapper"
    >
      <div
        ref="kt_aside_menu"
        id="kt_aside_menu"
        class="aside-menu my-4"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
        :class="asideMenuClass"
      >
        <perfect-scrollbar
          class="aside-menu scroll"
          style="max-height: 100vh; position: relative"
        >
          <KTMenu></KTMenu>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="aside-nav menu-desktop position-relative">
      <div
        v-if="showScrollUpButton"
        class="w-100 d-flex justify-center zindex-5 position-absolute top-0 left-0"
        style="background-color: #151b2c; height: 34px"
        @mouseover="() => handleScrollUpHover()"
      >
        <span class="svg-icon ml-5">
          <v-icon size="34" color="#fafafa">mdi-chevron-up</v-icon>
        </span>
      </div>
      <!--begin::Nav-->
      <ul
        @scroll="() => handleShowScrollButtons()"
        class="scrolling text-center py-6 position-relative h-100 w-100 overflow-y-hidden"
        style="
          overflow-y: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;
        "
      >
        <!--begin::Item-->
        <SidebarMain
          v-for="menu in menus"
          :key="menu.name"
          :item="menu"
        ></SidebarMain>

        <!--        <div class="p-2 mb-3">-->
        <!--          <div-->
        <!--            style="-->
        <!--              background: linear-gradient(167deg, #7e04b7, #c32c80) !important;-->
        <!--              border-radius: 8px;-->
        <!--              width: 100%;-->
        <!--              height: 55px;-->
        <!--            "-->
        <!--          >-->
        <!--            <v-tooltip bottom color="rgb(21, 27, 44)">-->
        <!--              <template v-slot:activator="{ on, attrs }">-->
        <!--                <button-->
        <!--                  v-bind="attrs"-->
        <!--                  v-on="on"-->
        <!--                  @click="() => $router.push({ name: 'iqai.ai' })"-->
        <!--                  class="d-flex align-center justify-content-center w-100 h-100"-->
        <!--                >-->
        <!--                  <span class="h1 mb-0 text-white custom-line-height poppins">-->
        <!--                    AI-->
        <!--                  </span>-->
        <!--                </button>-->
        <!--              </template>-->

        <!--              <span class="poppins"-->
        <!--                >IQ smart <span style="color: #c32c80">AI</span> tools, let our-->
        <!--                <span style="color: #c32c80">AI</span> engines help you enhance-->
        <!--                your products</span-->
        <!--              >-->
        <!--            </v-tooltip>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--end::Item-->
      </ul>
      <!--end::Nav-->
      <div
        v-if="showScrollDownButton"
        class="w-100 d-flex justify-center zindex-5 position-absolute bottom-0 left-0"
        style="background-color: #151b2c; height: 34px"
        @mouseover="() => handleScrollDownHover()"
      >
        <span class="svg-icon ml-5">
          <v-icon size="34" color="#fafafa">mdi-chevron-down</v-icon>
        </span>
      </div>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import { mapGetters } from "vuex";
import KTBrand from "@/view/layout/brand/Brand.vue";

import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import SidebarMain from "@/own/components/menu/SidebarMain.vue";
import KTMenu from "@/view/layout/aside/Menu.vue";
import { SET_PREVIOUS_SELECTED_MENU } from "@/core/services/store/sidebar.module";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      subMenuState: false,
      showScrollDownButton: false,
      showScrollUpButton: false,
    };
  },
  components: {
    KTBrand,
    KTMenu,
    SidebarMain,
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      this.$store.commit(
        SET_PREVIOUS_SELECTED_MENU,
        this.$route.name.split(".")[0]
      );

      this.handleShowScrollDownButton(1000);
    });
    window.addEventListener("resize", this.handleShowScrollDownButton);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleShowScrollDownButton);
  },
  watch: {
    $route() {
      this.$store.commit(
        SET_PREVIOUS_SELECTED_MENU,
        this.$route.name.split(".")[0]
      );
    },
  },
  methods: {
    handleScrollDownHover() {
      const element = document.querySelector(".scrolling");
      setTimeout(() => {
        this.showScrollDownButton = false;
        this.showScrollUpButton = true;
      }, 300);
      element.scroll({ top: element.scrollHeight, behavior: "smooth" });
    },
    handleShowScrollDownButton(ms = 300) {
      setTimeout(() => {
        let menuHeight = document.querySelector(".scrolling").scrollHeight;
        this.showScrollDownButton =
          menuHeight > document.documentElement.clientHeight * 0.89;
      }, ms);
    },
    handleScrollUpHover() {
      const element = document.querySelector(".scrolling");
      setTimeout(() => {
        this.showScrollDownButton = true;
        this.showScrollUpButton = false;
      }, 300);
      element.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    handleShowScrollButtons() {
      const element = document.querySelector(".scrolling");
      this.showScrollUpButton = element.scrollTop > 10;
      let scrollBottom =
        element.scrollHeight - element.scrollTop - element.clientHeight;
      this.showScrollDownButton = scrollBottom > 20;
    },
  },
  computed: {
    menus: function () {
      // total 16
      const menus = this.$store.getters.currentUser.menu
        .slice(0, 14)
        .map((item) => {
          if (item.type === "default") return item;
          else {
            const res = item.entities.filter(
              (subMenuItem) => subMenuItem.items.length
            );
            if (res.length) return { ...item, entities: [...res] };
          }
        });

      return menus.filter((item) => item !== undefined);
    },
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>

<style scoped>
.custom-line-height {
  line-height: 55px;
}
.menu-desktop {
  display: block !important;
  background-color: #151b2c !important;
  height: 100%;
}

.scrolling {
  max-height: 89vh;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-style: marquee-line;
  margin-bottom: 0;
}

.scrolling::-webkit-scrollbar {
  display: none;
}
.menu-mobile {
  display: none !important;
  z-index: 0;
}
@media screen and (max-width: 990px) {
  .menu-desktop {
    display: none !important;
  }
  .menu-mobile {
    display: block !important;
  }
}
</style>
