var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('v-menu',{staticClass:"overflow-hidden",attrs:{"min-width":"280","open-on-hover":"","close-on-content-click":false,"allow-overflow":"","close-delay":"60","h-100":"","ma-0":"","pa-0":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"w-100"},'div',attrs,false),on),[_c('inline-svg',{staticClass:"menu-svg",class:{
            'custom-opacity':
              _vm.item.name !== _vm.$store.getters.getPreviousSelectedMenu,
          },attrs:{"src":_vm.item.svg}})],1)]}}])},[_c('v-card',{staticClass:"sidebarSubMenu elevation-0 h-100 poppins"},[_c('v-card-text',{staticClass:"h3 uppercase pt-8 pr-0 text-black text-bold mb-0",staticStyle:{"color":"black","font-weight":"bold","line-height":"1.4"}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('v-card-text',{staticClass:"ma-0 pa-0 poppins"},[(_vm.item.type === 'default')?_c('v-list',[_vm._l((_vm.item.entities),function(val,index){return [(!val.hasOwnProperty('items'))?_c('v-list-item',{key:index},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":{ name: val.name }}},[_c('span',{staticClass:"ma-0 pa-0 font-size-h5 font-weight-bolder text-dark-50 text-hover-dark uppercase poppins"},[_vm._v(" "+_vm._s(val.title)+" ")])])],1)],1):_c('v-list-group',{key:index,staticClass:"px-0",attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-size-h5 font-weight-bolder text-dark-50 uppercase poppins",domProps:{"textContent":_vm._s(val.title)}})],1)]},proxy:true}],null,true)},_vm._l((val.items),function(child){return _c('v-list-item',{key:child.title,staticClass:"cursor-pointer bg-hover-light-info pl-8",class:{ 'bg-light-info': _vm.activeSubmenu === child.name },attrs:{"to":{ name: child.name },"exact":""},on:{"click":() => {
                    _vm.$router.push({ name: child.name });
                    _vm.activeSubmenu = child.name;
                  }}},[_c('span',{staticClass:"font-size-lg font-weight-medium text-dark-50 uppercase poppins"},[_vm._v(" "+_vm._s(child.title)+" ")])])}),1)]})],2):_c('v-list',_vm._l((_vm.item.entities),function(group){return _c('v-list-group',{key:group.title,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-size-h5 font-weight-bolder text-dark-50 uppercase poppins",domProps:{"textContent":_vm._s(group.title)}})],1)]},proxy:true}],null,true)},[_vm._l((group.items),function(child){return _c('v-list-item',{key:child.title,staticClass:"cursor-pointer bg-hover-light-info pl-8",class:{ 'bg-light-info': _vm.activeSubmenu === child.name },attrs:{"to":{ name: child.name },"exact":""},on:{"click":() => {
                    _vm.$router.push({ name: child.name });
                    _vm.activeSubmenu = child.name;
                  }}},[_c('span',{staticClass:"font-size-lg font-weight-medium text-dark-50 uppercase poppins"},[_vm._v(" "+_vm._s(child.title)+" ")])])})],2)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }